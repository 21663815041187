<template>
  <div class="page-start">
    <n-loader :loading="$var('load')" />
    <div v-if="quiz.bg" class="bg">
      <img :src="quiz.bg" alt="bg" />
    </div>
    <div v-if="quiz.logo" class="logo">
      <img :src="quiz.logo" alt="logo" />
    </div>
    <h1>{{ quiz.title }}</h1>
    <p v-if="quiz.content" v-html="xss(quiz.content)"></p>
    <n-link v-if="!$var('load')" :to="{ name: 'app.go', params: {code}, }"><n-button icon-right="angle-right">Начать</n-button></n-link>
  </div>
</template>

<script>
import xss from 'xss'

export default {
  name: 'PageStart',
  data() {
    return {
      quiz: {},
    }
  },
  html() {
    return {
      title: this.quiz.title,
      meta: [
        { mid: 'og:title', property: 'og:title', content: this.quiz.title, },
        { mid: 'og:description', property: 'og:description', content: this.quiz.content, },
        { mid: 'og:image', property: 'og:image', content: this.quiz.logo, },
        // { mid: 'theme-color', name: 'theme-color', content: this.quiz.color, },
      ],
    }
  },
  computed: {
    code() {
      return this.$route.params.code
    },
  },
  mounted() {
    this.load()
  },
  methods: {
    xss,
    load() {
      this.$var('load', true)
      $api.quiz.get(this.code).then((response) => {
        this.quiz = response.data.data
        this.see(this.quiz.id)
        document.querySelector('.layout-quiz').style.setProperty('--primary', this.quiz.color || '#00a600');
        this.$var('load', false)
      })
    },
    see(id) {
      $api.quiz.see(id).then()
    },
  },
}
</script>

<style lang="scss" scoped>
.page-start {
  text-align: center;
  height: 100vh;
  padding: 20px;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-x: hidden;

  h1 {
    margin: 0;
    padding: 70px 0 10px;
    font-size: 1.8em;
  }
  .n-button {
    margin: 20px 0;
    padding: 13px 35px;
    background: var(--primary);
    color: #fff;
  }

  .bg {
    opacity: .1;
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .logo {
    width: 100%;
    padding: 50px 0 0;
    text-align: center;
    img {
      max-width: 200px;
      max-height: 200px;
    }
  }

}
</style>
